import React, { useEffect, useState } from "react";
import Lupa from "../../../assets/img/icons/lupa.svg";
import Agregar from "../../../assets/img/icons/add-check.svg";
import historyIcon from "../../../assets/img/icons/cloneIcon.svg";
import GenericTable from "../../Layouts/GenericTableNew";
import tableStyles from "../../Layouts/tableStyle.module.scss";
import { Col, Row } from "react-bootstrap";
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import IndividualStyles from "../../TableIndividualAuction/IndividualAuction_CCS.module.scss";
import Styles from "../Hiring/Contract.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import ModalNew from "../../Layouts/ModalNew";
import { getListInvoices } from "../../../actions/InvoiceReceiptActions";
import { useDispatch, useSelector } from "react-redux";
import paginationStyles from "../../Layouts/pagination.module.scss";
import Pagination from "react-js-pagination";
import {
  getListContract,
  getSelectStatus,
  getSelectsDirCont,
  add_annex_contract,
  signContract,
  updateContractState,
  getPeriods,
  settlement,
} from "../../../actions/contractAction";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import FileInput from "../../Layouts/fileInput";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getAllConCenter } from "../../../actions/consumptionCenterActions";
import {
  customSelectNew,
  customSelectNewDark,
} from "../../Layouts/react-select-custom";
import CustomPopup from "../../Popup/customPopUpExtends";
import CreateContract from "./CreateContract";
import PdfIcon from "../../../assets/img/icons/pdfIcon.svg";
import SignaturePad from "react-signature-canvas";
import adj from "../../../assets/img/icons/Adjuntar.svg";
import {
  convertBase64,
  message,
  formatToRcSelect,
  today,
  convertMoneyFormat,
  loader,
} from "../../../helpers/helpers";
import x from "../../../assets/img/icons/Cerrar.svg";
import guion from "../../../assets/img/icons/guion.svg";
import triggerSrc from "../../../assets/img/icons/threeDots.svg";
import NumberFormat from "react-number-format";
import moment from "moment";
import { GET_PERIODS } from "../../../actions/actionTypes";
import SelectComponent from "../../SelectComponent/SelectComponent";
import { customSwaltAlert } from "../../../helpers/customSwaltAlert";
import { useGetMethod, usePostMethod } from "../../../Hooks/useFetch";
import CustomPopupStyles from "../../../components/Popup/customPopup.module.scss";
import { capitalizeWords } from "../../../helpers";
import { getSites } from "../../../actions/sitesActions";
import DynamicNumberFormat from "../../../shared/DynamicNumberFormat";

function ListContracts(props) {
  const MySwal = withReactContent(Swal);
  const store = useSelector((state) => state);
  const [contador, setContador] = useState(0);
  const dispatch = useDispatch();
  const counter = useSelector((state) => state);
  const periods = counter?.contractReducer?.periods;
  const createdBy = store.loginReducer.user_data.id;
  ;

  const [isLoading, setIsLoading] = useState(false);
  let hoy = new Date();
  const [trigger, setTrigger] = useState(1);

  const [docSignSw, setDocSignSw] = useState(false);
  const [endContract, setEndContract] = useState({
    show: false,
    showRD: false,
    data: {},
    result: {},
  });

  const location = useLocation();
  const justUpdated = location?.state?.justUpdated;
  const [inputs, setInputs] = useState({
    created_by: createdBy
  });
  const [temp, setTemp] = useState({});
  const [filters, setFilter] = useState({
    search: "",
    page: 1,
    perpage: 10,
    status: undefined,
    account: counter.loginReducer.currentAccount.id,
    area: undefined,
    position: "",
  });
  const token = store.loginReducer.Authorization;
  const [loading, setLoading] = useState(true);
  const [showDetail, setshowDetail] = useState({
    show: false,
    isEditing: false,
  });
  let history = useHistory();
  function handleClick() {
    history.push("/nomina/CrearContrato");
  }
  const myPermission =
    counter.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "hiring"
    );

  const [bonusSalaryOptions, setBonusSalaryOptions] = useState([]);

  const [triggerSelect, setTriggerSelect] = useState(0);
  useEffect(() => {
    let formattedBonusSalary = formatToRcSelect(
      periods.last_bonus_salary,
      "id",
      "sem",
      "year",
      "end",
      "year"
    );
    let options = [];
    if (formattedBonusSalary.length > 0) {
      formattedBonusSalary.forEach(option => {
        if (option.value === 0) {
          let label = option.label.split("-")[0];
          options.push({ ...option, label: label, value: "0" });
        } else {
          options.push(option);
        }
      });
      setBonusSalaryOptions(options);
      setTriggerSelect(triggerSelect + 1);
    }
  }, [periods?.last_severence]);




  useEffect(() => {
    dispatch(
      getSites({
        page: 1,
        perpage: 1000,
        id_account: counter.loginReducer.currentAccount.id,
      })
    );
    dispatch(getListContract(filters, () => setLoading(false)));
  }, [contador, filters.page, trigger]);

  useEffect(() => {
    dispatch(getListContract(filters));
  }, [showDetail]);
  const formattedLayoffs = formatToRcSelect(
    periods.last_severence,
    "id",
    "label",
    "year"
  );
  const optionSites = formatToRcSelect(
    counter.siteReducer.sites,
    "id",
    "description",
    "address"
  );



  const [endContractDate, setEndContractDate] = useState("");
  const formattedSeveranceInterest = formatToRcSelect(
    periods.last_severance_interest,
    "id",
    "label",
    "year"
  );
  const renderTooltip = (props) => (
    <Tooltip  {...props}>
        <div className={`${tableStyles.darkerGrayText}`}>
        Debe llenar los campos anteriores antes de seleccionar un motivo
        </div>
    </Tooltip>
);
  const formattedReasons = formatToRcSelect(periods.reasons, "id", "name");
  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/nomina/inicio");
    }

    dispatch(getSelectStatus({ prefix: "contracts" }));
    dispatch(
      getAllConCenter({
        eaccount: counter.loginReducer.currentAccount.id,
        lite: 1,
      })
    );

    dispatch(
      getSelectsDirCont({
        entity_account: counter.loginReducer.currentAccount.id,
        status: "verified",
      })
    );
    setshowDetail({ show: false, isEditing: false });
  }, []);

  const { results: getValidResults, load: getValidationLoader, trigger: getValidationMethod } = useGetMethod();


  const getValidation = () => {
    let error = ""
    if (!!inputs.reason === false) {
      error = "El motivo es obligatorio";
    }
    if (!!inputs.christmas_salary === false) {
      error = "El salario de navidad es obligatorio";
    }
    if (!!inputs.vacation_salary === false) {
      error = "El salario de vacaciones es obligatorio";
    }
    if (!!inputs.severance_pay === false) {
      error = "Las cesantías son obligatorias";
    }
    if (!!inputs.pre_notice === false) {
      error = "El pre-aviso es obligatorio";
    }
    if (!!inputs.overtime === false) {
      error = "Las horas extras son obligatorias";
    }
    if (!!inputs.end_date      === false) {
      error = "La fecha de finalización es obligatoria";
    }

    if (error === "" ){
      if (counter.contractReducer.country === "RD"){
        handleSubmit(false)

      }else{
        getValidationMethod({
        url: `/payroll/pending_settlement`,
        objFilters: {
          eaccount: counter.loginReducer.currentAccount.id, idEmployee: endContract?.data?.cand_id,
          endDate: endContractDate
        },
        token: token,
        doAfterSuccess: (res) => {
          let days = res?.results?.days > 0 ? true : false
          handleSubmit(days, res?.results?.days)
        }
      }).then((err) => {
        if (!err.success) {
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: err.message,
            confirmButtonText: "Aceptar",
            showCancelButton: false
          })
        }
      })
      }
    }else{
      message("warning", "Intenta de nuevo" , error, undefined, true )
    }









  }

  const handleSubmit = (showButton, daysRes) => {
    // try {
    // if (isEmptyOrUndefined(inputs.colaborator)) {
    //   setIsLoading(false);
    //   return message("warning", "Advertencia", "Seleccione un colaborador");
    // }
    customSwaltAlert({
      icon: "warning",
      title: "¿Está seguro?",
      text: `Se finalizará este contrato`,
      confirmButtonText: "Si, Continuar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      showDenyButton: showButton,
      denyButtonText: `Liquidar días`,
      denyButtonColor: `rgba(255, 139, 0, 1)`,
    }).then((result) => {
        if (result.isConfirmed || result.isDenied) {
          setIsLoading(true);
          let bodyData = result.isDenied ? { ...inputs, days: daysRes } : {...inputs, calculate_nominal_days: endContract.data.calculate_nominal_days}
          let country = counter.contractReducer.country
          const send = async () => {
            try {
              const result = await settlement(bodyData, token);
              if (result.success) {
                setIsLoading(false);
                history.push({
                  pathname: "/nomina/liquidacion",
                  state: {
                    endContract,
                    result,
                    endContractDate,
                    country
                  },
                });
                customSwaltAlert({
                  icon: "success",
                  title: "Finalizado exitosamente",
                  text: `Liquidación creada`,
                  confirmButtonText: "Aceptar",
                  showCancelButton: false,
                })
              } else {
                setIsLoading(false);
                customSwaltAlert({
                  icon: "warning",
                  title: "Intenta de nuevo",
                  text: result.message,
                  confirmButtonText: "Aceptar",
                  showCancelButton: false,
                })
              }
            } catch (error) {
              setIsLoading(false);
              customSwaltAlert({
                icon: "warning",
                title: "Intenta de nuevo",
                text: result.message,
                confirmButtonText: "Aceptar",
                showCancelButton: false,
              })
            }
          };
          send();
        }
    }).catch(err => {
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: err.message,
        confirmButtonText: "Aceptar",
        showCancelButton: false,
      });
    }).finally(() => {
      // setIsLoading(false);
    })

  };

  const Cycles = (item, value) => {
    dispatch({
      type: GET_PERIODS,
      payload: {},
    });
    if (hoy >= new Date(value)) {
      setInputs({
        ...inputs,
        contract: item?.contract_id,
        employee: item.cand_id,
        account: item.account,
        end_date: value,
      });
      dispatch(
        getPeriods({
          contract: item?.contract_id,
          end_date: moment(value).format("YYYY-MM-DD"),
        })
      );
    } else {
      customSwaltAlert({
        icon: "info",
        title: "Verificar",
        text: "la fecha de finalización no puede ser mayor al día de hoy",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        showCancelButton: false,
      });
    }
  };
  const handleChangeContractState = async (type, id, item) => {
    let action =
      type === "Pendiente" ? "canceled" : "Firmado" ? "canceled" : "finalize";
    try {
      customSwaltAlert({
        icon: "warning",
        title: "¿Está seguro?",
        text:
          type === "Pendiente"
            ? `Se anulará el contrato al empleado: ${capitalizeWords(item?.candidate)}`
            : `Se finalizará el contrato al empleado: ${capitalizeWords(item?.candidate)}`,
        confirmButtonText: "Si, Continuar",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const send = async () => {
            try {
              const result = await updateContractState(
                {
                  action: action,
                  id,
                },
                token
              );

              if (result.success) {
                setTrigger(trigger + 1);
                return message(
                  "success",
                  "Actualizado exitosamente",
                  `Se ha actualizado el contrato al empleado: ${capitalizeWords(item?.candidate)}`,
                  undefined,
                  true
                );
              } else {
                return message("warning", "Intenta de nuevo", result.message, undefined,
                  true);
              }
            } catch (error) {
              message(
                "warning",
                "Intenta de nuevo",
                "Ha ocurrido un error y no se pudo actualizar el estado del contrato",
                undefined,
                true
              );
            } finally {
              setLoading(false);
            }
          };
          send();
        }
      });
    } catch (error) {
      setLoading(false);
      return (
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Ha ocurrido un error y no se pudo actualizar el estado del contrato',
          showCancelButton: false
        })
      );
    }
  };

  const searchFilters = (e) => {
    // e?.preventDefault();
    setLoading(true);
    setFilter({
      ...filters,
      page: 1,
    });
    dispatch(getListContract({...filters, page: 1}, () => setLoading(false)));
  };

  const handlePageChange = (val) => {
    if (val !== filters.page) {
      setLoading(true);
    }
    setFilter({
      ...filters,
      page: val,
    });
    getListInvoices(filters);
  };

  let optionJobPos = [{ value: "", label: "Seleccionar..." }];
  if (Array.isArray(counter.contractReducer.job_positions)) {
    counter.contractReducer.job_positions.map((item) => {
      optionJobPos.push({
        label: item.job_title,
        value: item.id,
        name: "job_position",
      });
    });
  }

  const getToday = () => {
    let yourDate = new Date();
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
    return yourDate.toISOString().split("T")[0];
  };

  const [otrosSiControl, setOtrosSiControl] = useState({
    show: false,
    job_position: undefined,
    base_salary: "",
    non_const_income: 0, //ToDo
    start_date: "",
    entity_account: counter.loginReducer.currentAccount.id,
    created_by: counter.loginReducer.user_data.id,
    site_id: undefined,
    site_address: "",
    site_name: "",
    start_hour: "",
    end_hour: "",
  });

  const uploadOtroSi = () => {
    let error = "";

    if (!!otrosSiControl.signed_doc === false) {
      error = "Debe adjuntar un archivo para poder guardar";
    }
    if (!!otrosSiControl.job_position === false) {
      error = "Debe escoger un cargo";
    }
    if (!!otrosSiControl.base_salary === false) {
      error = "Debe digitar un salario";
    }
    if (Number(otrosSiControl.base_salary) < otrosSiControl.inmutable_base_salary) {
      error = `El campo 'Nuevo salario' debe ser mayor o igual a ${convertMoneyFormat(otrosSiControl.inmutable_base_salary)}`;
    }

    if (!!otrosSiControl.start_date === false) {
      error = "Debe asignar una fecha en el campo de vigencia";
    }
    if (!!otrosSiControl.site_id === false) {
      error = "Debe escoger una sede";
    }
    if (!!otrosSiControl.start_hour === false) {
      error = "Debe asignar una hora de inicio";
    }
    if (!!otrosSiControl.end_hour === false) {
      error = "Debe asignar una hora fin";
    }

    if (error === "") {
      dispatch(
        add_annex_contract(otrosSiControl.contract_id, otrosSiControl, () => {
          setOtrosSiControl({
            show: false,
            job_position: "",
            base_salary: 0,
            non_const_income: 0,
            start_date: "",
            entity_account: counter.loginReducer.currentAccount.id,
            created_by: counter.loginReducer.user_data.id,
          });
          searchFilters();
        }
        )
      );
    } else {
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: error,
        confirmButtonText: "Aceptar",
        showCancelButton: false
      });

    }
  };

  const closeModalOtroSi = () => {
    setOtrosSiControl({
      show: false,
      job_position: "",
      base_salary: "",
      non_const_income: 0, //ToDo
      start_date: "",
      // end_date: "2025-11-03",
      entity_account: counter.loginReducer.currentAccount.id,
      created_by: counter.loginReducer.user_data.id,
    });
  };
  const otroSiModal = (
    <ModalNew
      show={otrosSiControl.show}
      onHide={() => closeModalOtroSi()}
      title={"Agregar otrosí"}
      btnYesName={"Subir"}
      size={"500"}
      btnYesEvent={() => uploadOtroSi()}
      btnYesDisabled={counter.contractReducer.otrosi_loading}
    >
      <FileInput
        onChange={(file) =>
          setOtrosSiControl({
            ...otrosSiControl,
            //...file,
            signed_doc: file.base64,
            filename: file.full_name,

            name_doc: file.name_doc,
            type_doc: file.type_doc,
          })
        }
        name_doc={otrosSiControl.name_doc}
        type_doc={otrosSiControl.type_doc}
      />

      <div className="d-flex flex-column">
        <p className={tableStyles.crudModalLabel}>Cargo</p>
        {/* <input
            className={"register-inputs"}
            type="number"
            value={otrosSiControl.job_position}
            onChange={e=>setOtrosSiControl({...otrosSiControl, job_position:e.target.value})}
        /> */}
        <SelectComponent
          styles={customSelectNewDark}
          placeholder={"Seleccionar"}
          value={optionJobPos.find(
            (x) => x.value === otrosSiControl.job_position
          )}
          onChange={(e) =>
            setOtrosSiControl({
              ...otrosSiControl,
              job_position: e.value,
            })
          }
          options={optionJobPos}
        />
      </div>
      <div className="d-flex flex-column">
        <p className={tableStyles.crudModalLabel}>Sede</p>
        {/* <input
            className={"register-inputs"}
            type="number"
            value={otrosSiControl.job_position}
            onChange={e=>setOtrosSiControl({...otrosSiControl, job_position:e.target.value})}
        /> */}
        <SelectComponent
          placeholder={"Seleccionar..."}
          options={optionSites}
          key={"site_name" + trigger}
          isDisabled={props.show}
          value={optionSites.filter((x) => x.value === otrosSiControl.site_id)}
          styles={customSelectNewDark}
          onChange={(e) => {
            setOtrosSiControl({
              ...otrosSiControl,
              site_id: e.value,
              site_address: e.extra,
              site_name: e.label,
            });
          }}
        ></SelectComponent>
      </div>

      <div className="d-flex flex-column">
        <p className={tableStyles.crudModalLabel}>Hora inicio</p>
        <input
            className={"register-inputs"}
            type="time"
            value={otrosSiControl.start_hour}
            onChange={e=>setOtrosSiControl({...otrosSiControl, start_hour:e.target.value})}
        />
      </div>
      <div className="d-flex flex-column">
        <p className={tableStyles.crudModalLabel}>Hora fin</p>
        <input
          className={"register-inputs"}
          type="time"
          value={otrosSiControl.end_hour}
          onChange={e => setOtrosSiControl({ ...otrosSiControl, end_hour: e.target.value })}
        />
      </div>
      <div className="d-flex flex-column">
        <p className={tableStyles.crudModalLabel}>Nuevo salario</p>
        <NumberFormat
          className={"register-inputs"}
          value={otrosSiControl.base_salary}
          allowNegative={false}
          min={0}
          style={{ color: "#58595B" }}
          thousandsGroupStyle="thousand"
          thousandSeparator={"."}
          decimalSeparator={","}
          prefix={"$"}
          onValueChange={(values) => {
            const { value } = values;
            setOtrosSiControl({
              ...otrosSiControl,
              base_salary: value,
            });
          }}
          placeholder="$0"
        />
      </div>
      <div className="d-flex flex-column">
        <p className={tableStyles.crudModalLabel}>Ingreso no constitutivo</p>
        <NumberFormat
          className={"register-inputs"}
          value={otrosSiControl.non_const_income}
          allowNegative={false}
          min={0}
          style={{ color: "#58595B" }}
          thousandsGroupStyle="thousand"
          thousandSeparator={"."}
          decimalSeparator={","}
          prefix={"$"}
          onValueChange={(values) => {
            const { value } = values;
            setOtrosSiControl({
              ...otrosSiControl,
              non_const_income: value,
            });
          }}
          placeholder="$0"
        />
      </div>

      <div className="d-flex flex-column">
        <p className={tableStyles.crudModalLabel}>Vigencia desde</p>
        <input
          className={"register-inputs"}
          type="date"
          value={otrosSiControl.start_date}
          onChange={(e) =>
            setOtrosSiControl({ ...otrosSiControl, start_date: e.target.value })
          }
          style={{
            color: "#7FADDE",
            fontWeight: "bold",
          }}
          min={today()}
        />
      </div>
    </ModalNew>
  );
  const [showModal, setShowModal] = useState({
    show: false,
  });
  const [sigPad, setSigPad] = useState({});
  const [image, setImage] = useState({
    type: 0,
    filename: "",
    base64: "",
  });
  const [file, setFile] = useState({
    filename: "",
    base64: "",
    fileUrl: "",
  });
  const [uploadSw, setUploadSw] = useState(false);
  const handleImagePick = async (e) => {
    var f = e.target.files[0];
    var ext = f?.name?.split(".")[1];
    if (ext !== "pdf") {
      return message("warning", "Intente de nuevo", "El formato del archivo debe ser PDF", undefined, true);
    } else {
      if (!!e.target.files[0]) {
        let encode = await convertBase64(e.target.files[0]);
        let file64 = encode.split(",")[1]; // Delete base64 prefix (data:application...
        setFile({
          ...file,
          filename: e.target.files[0].name,
          base64: file64,
          fileUrl: URL.createObjectURL(e.target.files[0]),
        });
        sigPad.off();
      } else {
        sigPad.on();
      }
    }




  };
  const signPad = (ref) => {
    if (!!ref?.isEmpty()) {
      setDocSignSw(false);
    } else {
      setDocSignSw(true);
    }
    setSigPad(ref);
  };
  useEffect(() => {
    if (!!file?.filename || !!docSignSw) {
      setUploadSw(false);
    } else {
      setUploadSw(true);
    }
  }, [file, docSignSw]);

  const header = [
    <th className="pl-1 col-1 text-start px-2" >
      Empleados
    </th>,
    <th className="text-start">Nombre del cargo</th>,
    <th className="col-1 text-start">Área</th>,
    <th className="col-1 text-center">Fecha inicio</th>,
    <th className="col-1 text-center">Estado</th>,
    <th className="col-1 text-center"></th>,
  ];
  const body = [];
  if (Array.isArray(counter.contractReducer.results)) {
    counter.contractReducer.results.map((item) => {
      let thisDay = new Date(today()).getTime();
      let start = item.star_date;
      let parts = start.split("/");
      let startDate = new Date(parts[2], parts[1] - 1, parts[0]);
      body.push(
        <tr>
          <td className="text-start col-3 px-2">
            {item.candidate}
          </td>
          <td className="text-start col-3">{item.position}</td>
          <td className="text-start col-2">{item.area}</td>
          <td className="text-center col-2">{item.star_date}</td>
          <td className="text-center col-1">
            <div
              className="rounded-pill p-1"
              style={{
                backgroundColor: item.color,
              }}
            >
              <b style={{ color: item.fontcolor }}>{item.status}</b>
            </div>
          </td>

          <td className="col-1 text-end">
            <CustomPopup
              noHover
              triggerSrc={triggerSrc}
              showEdit={item.status === "Pendiente" && myPermission?.edit}
              editClickEvent={() => {
                setshowDetail({
                  show: false,
                  selectedContract: item,
                  isEditing: true,
                });
              }}
              showDetails={myPermission?.read}
              textDetails="Ver Detalle"
              showDetailsEvent={() => {
                setshowDetail({
                  show: true,
                  selectedContract: item,
                  isEditing: false,
                });
              }}
              showDelete={
                (item.status === "Finalizado" && !!myPermission?.edit
                  ? false
                  : item.status === "Anulado" && !!myPermission?.edit
                    ? false
                    : true)
              }
              showOtroSi={item.status === "Vigente" && !!myPermission?.edit}
              deleteEvent={
                item.status === "Pendiente"
                  ? () =>
                    handleChangeContractState(item.status, item.contract_id, item)
                  : item.status === "Firmado"
                    ? () =>
                      handleChangeContractState(item.status, item.contract_id,item)
                    : item.status === "Vigente" && startDate > thisDay
                      ? () =>
                        handleChangeContractState(item.status, item.contract_id,item)
                      : () => {
                        dispatch({
                          type: GET_PERIODS,
                          payload: {},
                        });
                        setTemp(item);
                        if (counter.contractReducer.country === "RD"){
                          setEndContract({...endContract, showRD: true, data: item });
                        }else{
                          setEndContract({...endContract, show: true, data: item });
                        }
                        setTriggerSelect(triggerSelect + 1)
                      }
              }
              otroSiEvent={() =>
                setOtrosSiControl({
                  ...otrosSiControl,
                  show: true,
                  contract_id: item.contract_id,
                  base_salary: item.base_salary,
                  non_const_income: item.non_const_income,
                  inmutable_base_salary: item.base_salary
                })
              }
              showSign={item.status === "Pendiente" && myPermission?.edit}
              signEvent={(e) =>
                setShowModal({ show: true, selectedContract: item.contract_id, candidate: item?.candidate })
              }
              // star_date

              deleteText={
                item.status === "Pendiente"
                  ? "Anular"
                  : item.status === "Firmado"
                    ? "Anular"
                    : item.status === "Vigente" && startDate > thisDay
                      ? "Anular"
                      : "Finalizar"
              }
              otroSi="Agregar otrosí"
              extraButtons={[{
                text: <span>Historial otrosí</span>,
                class: CustomPopupStyles.popUpGeneric,
                event: !!item?.contract_rearranged ? (e) => {
                  history.push({
                    pathname: "/nomina/contratos/historialOtrosi",
                    state: { data: item }
                  });
                } : null,
                icon: historyIcon
              }]}
            />
          </td>
        </tr>
      );
    });
  }

  const optionStatus = [
    { value: "", label: "Seleccionar...", name: "status" },
  ];

  if (Array.isArray(counter.contractReducer.resultsSlectStatus)) {
    counter.contractReducer.resultsSlectStatus.map((item) => {
      optionStatus.push({
        label: item.value,
        value: item.name,
        name: "status",
      });
    });
  }

  const optionArea = () => {
    let tempModules = [
      {
        value: "",
        label: "Seleccionar...",
      },
    ];
    if (Array.isArray(counter.consumptionCenterReducer.consumption_center)) {
      counter.consumptionCenterReducer.consumption_center.map((elem) => {
        tempModules.push({
          value: elem.id,
          label: elem.description,
        });
      });
    } else {
      tempModules.push({
        value: "noOptions",
        label: "No hay datos",
      });
    }
    return tempModules;
  };
  const clear = () => {
    setDocSignSw(false);
    setImage();
    setSigPad(sigPad.clear());
  };
  const uploadImage = async () => {
    let id_contract = showModal?.selectedContract;
    if (docSignSw) {
      const imagen = await sigPad.getTrimmedCanvas().toDataURL("image/png");
      let file64 = imagen.split(",")[1]; // Delete base64 prefix (data:application...;
      const body = {
        type: 2,
        file: {
          base64: file64,
          filename: "SignatureImage" + id_contract + ".png",
        },
      };
      dispatch(signContract(body, id_contract, () => doAfterSign(), showModal.candidate));
    } else {
      const body = {
        type: 1,
        file: {
          base64: file?.base64,
          filename: file?.filename,
        },
      };
      dispatch(signContract(body, id_contract, () => doAfterSign(), showModal.candidate));
    }
  };

  const doAfterSign = () => {
    setShowModal({ show: false });
    setImage({});
    setFilter({
      search: "",
      page: 1,
      perpage: 10,
      status: "",
      account: counter.loginReducer.currentAccount.id,
      area: "",
      position: "",
    });
    setTrigger(trigger + 1);
    clearModalSign();
  };
  const cleanFile = () => {
    setFile({});
    sigPad.on();
  };
  const clearModalSign = () => {
    clear();
    setFile({});
    setShowModal({ show: false });
  };

  const renderPrincipal = (
    <>
      {(counter.contractReducer.loading || getValidationLoader || counter.contractReducer.otrosi_loading || isLoading || loading
      ) && (
        loader
      )}
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: "3rem" }}
      >
        <div className="d-flex">
          <h1 className={tableStyles.title}>Contratos</h1>
        </div>
        {!!myPermission?.read &&
          <Row>
            <Col xs={2}>
              <p className={tableStyles.crudModalLabel}>Área</p>
              <SelectComponent
                value={optionArea().filter(
                  (x) => x.value === filters.area
                )}
                styles={customSelectNewDark}
                placeholder={"seleccionar..."}
                key={"area" + trigger}
                onChange={(e) => {
                  setLoading(true);
                  setFilter({
                    ...filters,
                    area: e.value,
                    page: 1,
                  });
                  dispatch(getListContract({ ...filters, area: e.value, page: 1 }, () => setLoading(false)));
                  //  searchFilters(e);
                }}
                options={optionArea()}
              ></SelectComponent>
            </Col>
            <Col xs={2}>
              <p className={tableStyles.crudModalLabel}>Estado</p>
              <SelectComponent
                value={optionStatus.filter(
                  (x) => x.value === filters.status
                )}
                styles={customSelectNewDark}
                placeholder={"Seleccionar..."}
                key={"state" + trigger}
                onChange={(e) => {
                  setLoading(true);
                  setFilter({
                    ...filters,
                    status: e.value,
                  });
                  dispatch(getListContract({ ...filters, status: e.value, page: 1 }, () => setLoading(false)));
                }}
                // noOptionsMessage={() => "No hay datos"}
                options={optionStatus}
              ></SelectComponent>{" "}
            </Col>
            <Col
              xs={4}
              className="d-flex align-self-end gap-1"
            >
              <input
                className={IndividualStyles.Search}
                value={filters.search}
                placeholder="Buscar..."
                type="text"
                onChange={(e) =>
                  setFilter({
                    ...filters,
                    search: e.target.value,
                  })
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    searchFilters(e);
                  }
                }}
              ></input>
              <img
                className="hoverPointer"
                alt="iconSearch"
                style={{}}
                src={Lupa}
                height="24px"
                onClick={(e) => searchFilters(e)}
              />
            </Col>
            {!!myPermission?.create ? (
              <Col
                xs={4}
                className="align-self-end"
              >
                <div className="d-flex justify-content-end">
                  <div
                    onClick={handleClick}
                    className={tableStyles.createNomModule}
                  >
                    <b className={`mr-2`}>Crear contrato</b>
                    <img
                      src={Agregar}
                      alt="User"
                    />
                    <div>
                    </div>
                  </div>
                </div>
              </Col>
            ) : null}
            {/* <Col xs={5}>
          </Col> */}
          </Row>
        }
        <div>
          <ModalNew
            show={showModal.show}
            onHide={() => clearModalSign()}
            title={"Firma del contrato"}
            btnYesEvent={() => uploadImage()}
            btnYesDisabled={!!uploadSw}
            btnYesName={"Subir"}
            size={"lg"}
          >
            <Row>
              <div className="col-5">
                <label className={tableStyles.crudModalLabel}>Documento firmado</label>
                {file.filename ? (
                  <img
                    src={x}
                    style={{
                      marginLeft: "5px",
                      zIndex: 3000,
                      position: "absolute",
                      top: "112px",
                      left: "276px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => cleanFile(e)}
                    alt="damnit"
                  />
                ) : (
                  ""
                )}
                <label
                  className={`${!!docSignSw ? Styles.registerInputsDisabled : Styles.registerInputsClear} hoverPointer d-flex`}
                  // style={{ backgroundColor: "#fff" }}
                  htmlFor="cvUpload"
                  disabled={docSignSw}
                >
                  {file.filename ? (
                    <img
                      src={PdfIcon}
                      alt=""
                      srcset=""
                      htmlFor="cvUpload"
                    />
                  ) : (
                    ""
                  )}
                  <span
                    className={`text-truncate ${tableStyles.crudModalLabel}`}
                    style={{
                      marginTop: "0.2rem",
                      width: "140px",
                      fontWeight: "bold",
                    }}
                  >
                    {!file.filename ? "Inserte PDF" : file.filename}
                  </span>
                  {!!!file.filename ? (
                    <img
                      style={{
                        marginLeft: "auto",
                        width: "16px",
                        alignSelf: "self-end",
                        marginBottom: "8px",
                      }}
                      src={adj}
                      alt=""
                    />
                  ) : (
                    ""
                  )}
                  <input
                    className={Styles.imageInputBtn}
                    type="file"
                    accept="application/pdf"
                    name=""
                    id="cvUpload"
                    disabled={docSignSw}
                    onChange={handleImagePick}
                  />
                </label>
              </div>
              <div
                className="col-1"
                style={{ alignSelf: "self-end" }}
              >
                <img
                  src={guion}
                  style={{ height: "168px" }}
                  alt="icon"
                />
              </div>
              <div className="col-6">
                <label className={tableStyles.crudModalLabel}>Firma digital</label>
                {!!docSignSw ? (
                  <img
                    src={x}
                    alt="icon"
                    style={{
                      marginLeft: "5px",
                      position: "absolute",
                      top: "112px",
                      zIndex: 30000,
                      right: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => clear()}
                  />
                ) : (
                  ""
                )}
                <div className={`${Styles.sigContainer} ${!file?.filename ? Styles.sigPad : Styles.sigPadDisabled}`}>
                  <SignaturePad
                    ref={(ref) => signPad(ref)}
                    canvasProps={{ width: 311, height: 167 }}
                  />
                </div>
              </div>
            </Row>
          </ModalNew>
        </div>
        {!!myPermission?.read &&
          <>
            <GenericTable
            lowerCase
              headers={header}
              children={body}
              dark={true}
            ></GenericTable>
            {!!counter.contractReducer.total ? (
              <div className={paginationStyles.wrapper}>
                <p className={` ${paginationStyles.paginationText} darkGray`}>
                  pag. {counter.contractReducer.total ? filters.page : ""}
                  {" de "}
                  {Math.ceil(counter.contractReducer.total / filters.perpage) ? Math.ceil(counter.contractReducer.total / filters.perpage) : ""} (
                  {" " + counter.contractReducer.total}
                  {" encontrados"})
                </p>
                <Pagination
                  activePage={filters.page}
                  itemsCountPerPage={filters.perpage}
                  totalItemsCount={counter.contractReducer.total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  itemClassPrev={`${paginationStyles.itemClassPrev} `}
                  itemClassNext={`${paginationStyles.itemClassNext} `}
                  itemClassFirst={`${paginationStyles.itemClassFirst} `}
                  itemClassLast={`${paginationStyles.itemClassLast} `}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            ) : null}
          </>}
      </div>
      {otroSiModal}
    </>
  );

  const [triggerI, setTriggerI] = useState(0)
  return !!showDetail.show ? (
    <CreateContract
      information={showDetail.selectedContract}
      show={showDetail.show}
      isEditing={showDetail.isEditing}
      setshowDetail={setshowDetail}
      setFilters={setFilter}
    />
  ) : !!showDetail.isEditing ? (
    <CreateContract
      information={showDetail.selectedContract}
      show={showDetail.show}
      isEditing={showDetail.isEditing}
      setshowDetail={setshowDetail}
      setFilters={setFilter}
      cleanDetails={() =>
        setshowDetail({
          show: false,
          isEditing: false,
        })
      }
    />
  ) : (

    <>
      <ModalNew
        show={endContract.showRD}
        onHide={() => {
          setEndContract(state => (
            { ...state,
              data: { ...state.data, candidate: '', star_date: '' },
              showRD: false
            }))
          setEndContractDate('')
          setInputs(state => ({
            ...state,
            last_severance_pay: '',
            last_salary_bonus: '',
            last_severance_interest: '',
            reason: ''
          }))
        }}
        title={"Finalización de contrato"}
        btnYesName={"Aceptar"}
        btnYesEvent={() => getValidation()}
        subtitle={`Tipo de contrato: ${endContract?.data?.contract_typename}`}
        size={"500"}
      >
        {/* /* ---------------------------------- FIRST --------------------------------- */}
        <Row>
          <Col xs={6}>
            {" "}
            <p className={`${tableStyles.crudModalLabel} `}>Empleado</p>
            <input
              disabled={true}
              value={endContract?.data?.candidate}
              className="register-inputs text-secondary"
              name="position"
            />
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Fecha de inicio</p>
            <input
              disabled={true}
              value={endContract?.data?.star_date}
              className="register-inputs text-secondary"
              name="position"
            />
          </Col>
        </Row>
        {/* /* ---------------------------------- SECOND --------------------------------- */}
        <Row>
          <Col xs={6}>
            {" "}
            <p className={`${tableStyles.crudModalLabel} `}>
              Fecha de finalización
            </p>
            <input
              type="date"
              max={moment(hoy).format("YYYY-MM-DD")}
              // value={setDate}
              // disabled={true}
              className="register-inputs text-secondary"
              name="position"
              onChange={(e) => {
                Cycles(temp, e.target.value);
                setEndContractDate(e.target.value);
              }}
            />
          </Col>
          <Col xs={6}>
            {" "}
            <p className={`${tableStyles.crudModalLabel} `}>
              Salario base
            </p>
            <DynamicNumberFormat
              inputType={"money"}
              allowNegative={false}
              className={`register-inputs`}
              thousandsGroupStyle="thousand"
              defaultValue={convertMoneyFormat(endContract.data.base_salary ?? "-")}
              onValueChange={(values) => {
                setInputs({ ...inputs, base_salary: values?.value })
                setTriggerI(triggerI + 1)
              }}
            />

          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            {" "}
            <p className={`${tableStyles.crudModalLabel} `}>
              Horas extras
            </p>
            <DynamicNumberFormat
            inputType={"money"}
            allowNegative={false}
              className={`register-inputs`}
              thousandsGroupStyle="thousand"
              defaultValue={""}
              onValueChange={(values) => {
                setInputs({ ...inputs, overtime: values?.value })
                setTriggerI(triggerI + 1)
              }}
            />
          </Col>
          <Col xs={6}>
            {" "}
            <p className={`${tableStyles.crudModalLabel} `}>
              Pre-aviso
            </p>
            <DynamicNumberFormat
              inputType={"money"}
            allowNegative={false}
              className={`register-inputs`}
              thousandsGroupStyle="thousand"
              defaultValue={""}
              onValueChange={(values) => {
                setInputs({ ...inputs, pre_notice: values?.value })
                setTriggerI(triggerI + 1)
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            {" "}
            <p className={`${tableStyles.crudModalLabel} `}>
              Cesantías
            </p>
            <DynamicNumberFormat
              inputType={"money"}
              allowNegative={false}
              className={`register-inputs`}
              thousandsGroupStyle="thousand"
              defaultValue={""}
              onValueChange={(values) => {
                setInputs({ ...inputs, severance_pay : values?.value })
                setTriggerI(triggerI + 1)
                // setDetailPayroll({...detailPayroll, inscBonusPayment: values})
              }}
            />
          </Col>
          <Col xs={6}>
            {" "}
            <p className={`${tableStyles.crudModalLabel} `}>
              Salario de vacaciones
            </p>
            <DynamicNumberFormat
              inputType={"money"}
              allowNegative={false}
              className={`register-inputs`}
              thousandsGroupStyle="thousand"
              defaultValue={""}
              onValueChange={(values) => {
                setInputs({ ...inputs, vacation_salary: values?.value })
                setTriggerI(triggerI + 1)
                // setDetailPayroll({...detailPayroll, inscBonusPayment: values})
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            {" "}
            <p className={`${tableStyles.crudModalLabel} `}>
              Salario de navidad
            </p>
            <DynamicNumberFormat
              inputType={"money"}
              allowNegative={false}
              className={`register-inputs`}
              thousandsGroupStyle="thousand"
              defaultValue={""}
              onValueChange={(values) => {
                setInputs({ ...inputs, christmas_salary: values?.value })
                setTriggerI(triggerI + 1)
              }}
            />
          </Col>
          <Col xs={6}>
            {" "}
            <p className={`${tableStyles.crudModalLabel} `}>
              Total prestaciones y salarios
            </p>
            <DynamicNumberFormat
              inputType={"money"}
              allowNegative={false}
              disabled
              key={triggerI}
              className={`register-inputs`}
              thousandsGroupStyle="thousand"
              defaultValue={convertMoneyFormat((Number(endContract.data.base_salary) + Number(inputs?.overtime ?? 0) +
                Number(inputs?.pre_notice ?? 0) + Number(inputs?.vacation_salary  ?? 0) + Number(inputs?.severance_pay ?? 0) + Number(inputs?.christmas_salary  ?? 0)
                )?? "-")}
              onValueChange={(values) => {
                // setDetailPayroll({...detailPayroll, inscBonusPayment: values})
              }}
            />
          </Col>
        </Row>
        {/* /* ---------------------------------- THIRD --------------------------------- */}
        {/* /* ---------------------------------- THIRD --------------------------------- */}
        <Row>
          <Col xs={12} className="mt-2">
            {" "}
            <h6 className={`${tableStyles.darkBlueText} text-center`}>
              <b>Motivo</b>
            </h6>
            <hr></hr>
            <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >
            <SelectComponent
              onChange={(e) => setInputs({ ...inputs, reason: e.value })}
              options={formattedReasons}
              className="text-secondary"
              placeholder={"Seleccionar..."}
              styles={customSelectNew}
              value={formattedReasons.find((e) => e.value === inputs.reason)}
            ></SelectComponent>
            </OverlayTrigger>
          </Col>
        </Row>
      </ModalNew>
      <ModalNew
        show={endContract.show}
        onHide={() => {
          //   setEndContract({ ...endContract, show: false, data: {}, result: {} })
          //   setInputs({ ...inputs, last_severance_pay: "",
          //   last_salary_bonus: "",
          //   last_severance_interest: "",
          //   reason: "",
          // })

          setEndContract(state => (
            {
              ...state,
              data: { ...state.data, candidate: '', star_date: '' },
              show: false
            }));

          setEndContractDate('');

          setInputs(state => ({
            ...state,
            last_severance_pay: '',
            last_salary_bonus: '',
            last_severance_interest: '',
            reason: ''
          }));


        }}
        title={"Finalización de contrato"}
        btnYesName={"Aceptar"}
        btnYesEvent={() => getValidation()}
        subtitle={`Tipo de contrato: ${endContract?.data?.contract_typename}`}
        size={"500"}
      // btnYesEvent={() => handleChangeContractState(item.status, item.contract_id)}
      >
        {/* /* ---------------------------------- FIRST --------------------------------- */}
        <Row>
          <Col xs={6}>
            {" "}
            <p className={`${tableStyles.crudModalLabel} `}>Empleado</p>
            <input
              disabled={true}
              value={endContract?.data?.candidate}
              className="register-inputs text-secondary"
              name="position"
            />
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Fecha de inicio</p>
            <input
              disabled={true}
              value={endContract?.data?.star_date}
              className="register-inputs text-secondary"
              name="position"
            />
          </Col>
        </Row>
        {/* /* ---------------------------------- SECOND --------------------------------- */}
        <Row>
          <Col xs={6}>
            {" "}
            <p className={`${tableStyles.crudModalLabel} `}>
              Fecha de finalización
            </p>
            <input
              type="date"
              max={moment(hoy).format("YYYY-MM-DD")}
              // value={setDate}
              // disabled={true}
              className="register-inputs text-secondary"
              name="position"
              onChange={(e) => {
                Cycles(temp, e.target.value);
                setEndContractDate(e.target.value);
              }}
            />
          </Col>
          {periods?.last_severence?.length > 0 && (
            <Col xs={6}>
              <>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Último periodo de cesantías
                </p>
                <SelectComponent
                  onChange={(e) =>
                    setInputs({ ...inputs, last_severance_pay: e.extra })
                  }
                  options={formattedLayoffs}
                  className="text-secondary"
                  placeholder={"Seleccionar..."}
                  styles={customSelectNew}
                  value={formattedLayoffs.find((e) => e.extra === inputs.last_severance_pay)}

                ></SelectComponent>
              </>
            </Col>
          )}
        </Row>
        {/* /* ---------------------------------- THIRD --------------------------------- */}
        <Row>
          {periods?.last_bonus_salary?.length > 0 && (
            <Col xs={6}>
              {" "}
              <>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Último periodo de primas
                </p>
                <SelectComponent
                  key={triggerSelect + "select"}
                  onChange={(e) => {
                    let value = e.extra2 === "0" ? "0" : e.extra + "-" + e.extra2;
                    setInputs({
                      ...inputs,
                      // last_salary_bonus: e.extra + "-" + e.extra2,
                      last_salary_bonus: value,
                    });
                  }}
                  options={bonusSalaryOptions}
                  className="text-secondary"
                  placeholder={"Seleccionar..."}
                  styles={customSelectNew}
                  value={bonusSalaryOptions.find((e) => e.extra2 === "0" ? "0" : e.extra + "-" + e.extra2 === inputs.last_salary_bonus)}
                ></SelectComponent>
              </>
            </Col>
          )}
          {periods?.last_severance_interest?.length > 0 && (
            <Col xs={6}>
              <>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Último periodo de intereses
                </p>
                <SelectComponent
                  onChange={(e) =>
                    setInputs({ ...inputs, last_severance_interest: e.extra })
                  }
                  options={formattedSeveranceInterest}
                  className="text-secondary"
                  placeholder={"Seleccionar..."}
                  styles={customSelectNew}
                  value={formattedSeveranceInterest.find((e) => e.extra === inputs.last_severance_interest)}
                ></SelectComponent>
              </>
            </Col>
          )}
        </Row>
        {/* /* ---------------------------------- THIRD --------------------------------- */}
        <Row>
          <Col xs={12} className="mt-2">
            {" "}
            <h6 className={`${tableStyles.darkBlueText} text-center`}>
              <b>Motivo</b>
            </h6>
            <hr></hr>
            <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >
            <SelectComponent
              onChange={(e) => setInputs({ ...inputs, reason: e.value })}
              options={formattedReasons}
              className="text-secondary"
              placeholder={"Seleccionar..."}
              styles={customSelectNew}
              value={formattedReasons.find((e) => e.value === inputs.reason)}
            ></SelectComponent>
            </OverlayTrigger>
          </Col>
        </Row>
      </ModalNew>
      {renderPrincipal}
    </>
  );
}
export default ListContracts;
